const Backbone = require('Backbone');
const _ = require('underscore');
const GroupType = require('@common/data/enums/GroupType');
const FilterableAttributesEnum = require('@common/data/enums/FilterableAttributesEnum');
const ExclusionRulesEnum = require('@common/data/enums/ExclusionRulesEnum');

const { getRuleFilterableAttributes } = require('@common/libs/helpers/app/FilterableAttributesHelpers');

const filterParamsValueMapper = _.partial(_.pick, _, 'id', 'areaOfInterest', 'teamAttribute', 'filterRule');

class FilterGroup extends Backbone.Model {
  static filterableAttributes = FilterableAttributesEnum.values();

  static teamOnlyAttributes = [
    FilterableAttributesEnum.Locations,
    FilterableAttributesEnum.LocationsFilterRule,
    FilterableAttributesEnum.LocationTypes,
    FilterableAttributesEnum.LocationTypesFilterRule
  ];

  static filterRuleAttributes = getRuleFilterableAttributes();

  static NO_GROUP_ID = -1;

  defaults() {
    return {
      name: ''
    };
  }

  apiEndpoint() {
    return GroupType.getGroupEndpointByType(this.get('type'));
  }

  get needsFetch() {
    return this.has('needsFetch') ? this.get('needsFetch') : _.isEmpty(this.pick(FilterGroup.filterableAttributes));
  }

  getFilterParams() {
    const criteria = this.isTeamOnly() ? FilterGroup.teamOnlyAttributes : FilterGroup.filterableAttributes;
    const filterableAttributes = this.getFilterableAttributes(criteria);

    return _.mapObject(filterableAttributes, (value, key) => {
      // Prevent the rule values (like locationsFilterRule) from being passed into the map call below.
      // These values are strings, not arrays
      if (value != null && (FilterGroup.filterRuleAttributes.includes(key) || key === FilterableAttributesEnum.DateCondition)) {
        return value;
      }

      return (value ?? []).map(filterParamsValueMapper);
    });
  }

  getFilterableAttributes(criteria = FilterGroup.filterableAttributes) {
    return _.pick(this.attributes, criteria);
  }

  parse(res = {}) {
    const json = (res && res.entity) || res;
    json.needsFetch = _.isEmpty(_.pick(json, FilterGroup.filterableAttributes));
    return json;
  }

  isNew() {
    return super.isNew() || this.isDefaultFilter();
  }

  inflateData(options = {}) {
    if (this.hasXHR()) {
      return this.getXHR();
    }

    if (!this.isNew() && this.needsFetch) {
      return this.fetch(options);
    }

    return new $.Deferred().resolve()
      .promise();
  }

  toOption() {
    const id = this.get('id');
    const name = this.get('name');
    const type = this.get('type');
    return {
      id,
      value: name,
      type
    };
  }

  matches(text) {
    if (!text) {
      return true;
    }
    return this.get('name').toLowerCase()
      .indexOf(text.toLowerCase()) > -1;
  }

  hasEmptyFilterParam(paramName) {
    const param = this.get(paramName);

    if (param != null) {
      return !(param.length > 0);
    }
    return true;

  }

  isTeamFilter() {
    return this.get('type') === GroupType.TeamFilter;
  }

  isAdHocFilter() {
    return this.get('isAdHocFilter');
  }

  isDefaultFilter() {
    return this.id === FilterGroup.NO_GROUP_ID;
  }

  isTeamOnly() {
    const filterableAttributes = this.getFilterableAttributes();
    return _.every(_.pairs(filterableAttributes), ([key, value]) => {
      // We must be sure the key is a team attribute (locations or locationType), while ignoring non-team attributes
      // that has no selections (jobTitles: [] has a zero length, for example), and ignore all non-team filter rule
      // attributes (like linesofBusinessFilterRule) to be certain that the filter can be considered team-only
      return FilterGroup.teamOnlyAttributes.includes(key)
        || value.length === 0
        || FilterGroup.filterRuleAttributes.includes(key);
    });
  }

  hasExclusions() {
    // Short-circuit and return true when the first filter rule with a value of EXCLUDE is found
    return _.some(getRuleFilterableAttributes(), (attribute) => {
      return this.get(attribute) === ExclusionRulesEnum.EXCLUDE;
    });
  }
}

module.exports = FilterGroup;
